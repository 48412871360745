.no-ripple {
  --ripple-color: transparent;
}

.container {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 2px solid black;
  border-radius: 8px;
  padding: 8px 10px;
  font-size: 17px;
  margin-bottom: 15px;
}

.btn-disabled {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 2px solid rgb(209, 209, 209);
  border-radius: 8px;
  padding: 8px 10px;
  font-size: 17px;
  color: rgb(209, 209, 209);
  margin-bottom: 15px;
}

/* .diary-container {
  display: flex;
  flex-direction: column;
} */

.lane {
  display: flex;
  align-items: center;
  margin: 2px;
}

.lane-icon {
  width: 18px;
  height: 18px;
}

.lane .center {
  margin: unset;
  margin-left: 5px;
}

.button-inner {
  justify-content: space-between;
}

ul{
  list-style:none;
  margin:0;
  padding:0;
}
.ratio-4-3{
  width:100%;
  position:relative;
  background:url() 50% 50% no-repeat;
  background-size:cover;
  background-clip:content-box;
}
.ratio-4-3:before{
    display:block;
    content:"";
    padding-top:75%;
}

.description{
  display: flex;
  flex-wrap: wrap;
  width: 100%; 
  word-break: break-word;
}
