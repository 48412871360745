.modal {
  height: 100px !important;
}

.text {
  padding: 10px;
  margin: 25px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 20px;
}
