.diary-avatar {
  width: 90px;
  height: 90px;
}

.diary-title h1 {
  font-size: 18px;
}

.diary-title h2 {
  font-size: 16px;
}

.repo-avata-image {
  width: 80px;
  height: 80px;
}

.spacing-between {
  justify-content: space-between !important;
}

.diary-title {
  display: flex;
  flex-direction: column;
  width: 100%;
}
