ion-icon {
  font-size: 30px;
  color: black;
}

@media screen and (min-width: 600px) {
  ion-app {
    width: 600px;
    margin: auto;
  }
}

::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
}

ion-modal {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

ion-input {
  caret-color: black;
}

ion-textarea {
  caret-color: black;
}

ion-toast {
  --button-color: black;
}

ion-tab-button {
  color: black;
}

:root {
  width: 100%;
  background-color: white;
}

.tabbar {
  padding-top: 5px;
  padding-bottom: 5px;
  height: 60px;
}

.tabbar-ios {
  padding-top: 5px;
  padding-bottom: 15px;
  min-height: 80px;
  margin-bottom: 30;
  --color: black;
}

.tabbar ion-label {
  color: black;
  padding-bottom: 5px;
}

.toolbar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;

}

.toolbar-spacing {
  margin-top: 10px;
}

.toolbar-spacing-sm {
  margin-top: 12px;
}

.ios-top-spacing {
  padding-top: 30px;
}

.toolbar-spacing-image {
  margin-top: 9px;
}

a {
  color: gray !important;
}

.mt-15 {
  margin-top: 15px;
}

.calendar-day-active {
  background-color: red !important;
}

ion-content {
  --keyboard-offset: 0px;
}

.scroll-content {
  padding-bottom: 0 !important;
}

