.tool-bar {
}

.tool-bar ion-item {
  --ripple-color: transparent;
  --background: transparent;
}

.tool-bar .icon-home {
  height: 36px;
  width: 36px;
  border-radius: 0;
}

.tool-bar .icon-add {
  height: 36px;
  width: 36px;
}

.button-native {
  --padding-start: 15px;
}
