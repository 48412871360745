.info-page ion-title {
  font-size: 32px;
}

.footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

ion-button {
  color: black;
}

ion-item {
  height: "65px";
}

ion-toolbar {
  background-color: #c8c8c8;
}
