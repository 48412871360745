.phonebook-segment {
  background-color: #c8c8c8;
  border-radius: unset;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
}

.phonebook-segment-button {
  --indicator-box-shadow: none;
}

.segment-button-checked {
  color: black !important;
}

.ion-segment {
  background-color: #2d303aab;
}

.search-bar {
}

.phonebook-header {
}

.phonebook-entries {
  margin-top: 190px;
}

.phonebook-title {
  padding: 15px;
  font-weight: bold;
  font-size: 18px;
  background-color: #f4f5f8;
}

.segment-button-checked::part(indicator-background) {
  background-color: #fbf315;
}
