.address {
  padding: 20px;
  padding-top: 15px;
  --color: initial;
  display: block;
  color: var(--color);
  font-family: var(--ion-font-family, inherit);
  font-size: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 17px;
  display: flex;
  justify-content: space-between;
}

h1 {
  margin-top: 0px;
  font-weight: bold;
}

.address-left {
}

.maps {
  width: 50%;
  border-radius: 10px;
}

h4 {
  margin-left: 20px;
  margin-bottom: 0px;
}

.link {
  text-decoration: none;
}

.side-by-side {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.container {
}

.additional-entries {
  margin-left: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}
