/* #app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
} */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: var(--ion-color-primary);
}

.swiper-pagination-bullet-active {
  background-color: #fbf315 !important;
}

h1 {
  margin-bottom: 0px;
}

p {
  padding-top: 0px;
  margin-top: 2px;
}

.image {
  height: 600px;
  object-fit: cover;
}

.container {
  width: 100%;
  padding-bottom: 0px;
  position: relative;
  overflow: hidden;
}

.square-box {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.square-box:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.square-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: white;
  text-align: center;
}

ul{
  list-style:none;
  margin:0;
  padding:0;
}
.ratio-4-3{
  width:100%;
  position:relative;
  background:url() 50% 50% no-repeat;
  background-size:cover;
  background-clip:content-box;
}
.ratio-4-3:before{
    display:block;
    content:"";
    padding-top:75%;
}
