.btn {
  position: fixed;
  margin-left: 10px;
  width: 3rem;
  height: 3rem;

  --border-radius: 50%;
  --background: rgba(222, 222, 222, 0.43);
  --box-shadow: 0;

  z-index: 10;
}


.btn-ios {
  position: fixed;
  margin-left: 10px;
  margin-top: 40px;
  width: 3rem;
  height: 3rem;

  --border-radius: 50%;
  --background: rgba(222, 222, 222, 0.43);
  --box-shadow: 0;

  z-index: 10;
}
.icon {
}
