.diary-content-page ion-title {
  font-size: 32px;
}

.diary-content-page ion-button {
  margin-top: 17px;
  margin-bottom: 17px;
}

.nores {
  height: calc(100% - 150px);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  text-align: center;
  margin: 30px;
}
