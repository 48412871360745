ion-modal#example-modal {
  --height: 30%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal#example-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}

ion-modal#example-modal ion-toolbar {
  --background: rgb(14 116 144);
  --color: white;
}

/* Alert Interface: set color for checked alert options (single selection) */
.my-custom-interface
  .select-interface-option[aria-checked="true"]
  .alert-radio-label {
  color: black;
}

.my-custom-interface
  .select-interface-option[aria-checked="true"].sc-ion-alert-ios
  .alert-radio-inner.sc-ion-alert-ios {
  border-color: var(--ion-text-color, #000);
}

.alert-button.sc-ion-alert-ios {
  color: black !important;
}
