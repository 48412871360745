.buttons {
  justify-content: space-around;
}

.preview {
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid #f4f5f8;
}

.modal {
  height: 100px !important;
}

.delete {
  display: flex;
  justify-content: flex-end;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone-container {
  position: relative;
}

.dropzone {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
input[type="file"] {
  opacity: 0;
  width: 100%;
}

/* Alert Interface: set color for checked alert options (single selection) */
.my-custom-interface
  .select-interface-option[aria-checked="true"]
  .alert-radio-label {
  color: black;
}

.my-custom-interface
  .select-interface-option[aria-checked="true"].sc-ion-alert-ios
  .alert-radio-inner.sc-ion-alert-ios {
  border-color: var(--ion-text-color, #000);
}

.alert-button.sc-ion-alert-ios {
  color: black !important;
}

ion-modal#diary-modal {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  background-color: rgba(0, 0, 0, 0.4);
}

ion-modal#diary-modal h1 {
  margin: 20px 20px 10px 20px;
}

ion-modal#diary-modal ion-icon {
  margin-right: 6px;

  width: 48px;
  height: 48px;

  padding: 4px 0;

  color: #aaaaaa;
}

ion-modal#diary-modal .wrapper {
}

.div-title {
  display: flex;
  margin: 5px;
  font-weight: bold;
  font-size: large;
}

ion-action-sheet.ja-nein-sheet .action-sheet-group {
  --button-color: black;
}

ion-action-sheet.ja-nein-sheet .action-sheet-cancel {
  --background: #f58840;
  --backdrop-opacity: 0.6;
  --button-background-selected: rgb(255, 255, 0, 0.8);
}
