.repo-avata-image {
  width: 62px;
  height: 62px;
  /* margin: 8px;
  margin-right: 15px; */
}

.repo-title {
  white-space: normal;
}
