.buttons {
	justify-content: space-around;
}

.modal {
	height: 100px !important;
}

.preview {
	flex: 1;
	display: flex;
	justify-content: space-between;
	padding-bottom: 5px;
	border-bottom: 1px solid #f4f5f8;
}

.delete {
	display: flex;
	justify-content: flex-end;
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.dropzone-container {
	position: relative;
}

.dropzone {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
input[type='file'] {
	opacity: 0;
	width: 100%;
}

/* Alert Interface: set color for checked alert options (single selection) */
.my-custom-interface .select-interface-option[aria-checked='true'] .alert-radio-label {
	color: black;
}

.my-custom-interface .select-interface-option[aria-checked='true'].sc-ion-alert-ios .alert-radio-inner.sc-ion-alert-ios {
	border-color: var(--ion-text-color, #000);
}

.alert-button.sc-ion-alert-ios {
	color: black !important;
}

ion-action-sheet.ja-nein-sheet .action-sheet-group {
	--button-color: black;
}

ion-action-sheet.ja-nein-sheet .action-sheet-cancel {
	--background: #f58840;
	--backdrop-opacity: 0.6;
	--button-background-selected: rgb(255, 255, 0, 0.8);
}

.info-text {
	font-size: 13px;
	font-weight: 450;
	color: rgba(10, 10, 10, 0.643);
}

.spinner {
	display: flex;
	margin: auto;
}
