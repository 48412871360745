.search {
  background-color: #c8c8c8;
  padding: 10px 15px;
  padding-top: 0px;
  box-sizing: none;
  position: relative;
}

.search-icon {
  position: absolute;
  width: 15px;
  height: calc(100% - 10px);
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search input {
  padding-left: 32px;
  background-color: white;
  width: 100%;
  height: 40px;
  border-color: transparent;
  border-radius: 20px;
  border: 0px;
}

:focus {
  outline: none;
}
